import React from 'react';
import dot from 'dot-object'
import styled from "styled-components";


export const DropdownSession = props => {

    if(props.element.chained && props.element.chained.role === 'slave') {
        return <SlaveAdapter
            {...props}
            errors={props.errors?.[0] || []}
            value={props.value?.[0] || ""}
            changeValue={value => props.changeValue([value])}
        />
    }

    if(props.element.multipleValues) {
        return <MultipleDropdown {...props}/>
    }

    return <SimpleDropDown
        {...props}
        errors={props.errors?.[0] || []}
        value={props.value?.[0] || ""}
        changeValue={value => props.changeValue([value])}
    />
}



const SlaveAdapter = props => {
    const [items, setItems] = React.useState([])

    React.useEffect(() => {
        const items = calculateItemsFromMaster()
        if(items.length === 0 && props.value) props.changeValue('')
        setItems(items)
    }, [props.answers_by_code])
    
    const calculateItemsFromMaster = () => {
        const elements = props.form.steps.reduce((acc, step) => [...acc, ...step.elements], [])

        const chainedValues = [
            props.answers_by_code[props.element.chained.masterCode] || null,
            ...(
                elements
                .filter(element => element.type === 'dropdown' && element.chained && element.chained.role === 'slave' && element.chained.masterCode === props.element.chained.masterCode && element.chained.chainIndex < props.element.chained.chainIndex)
                .sort((a, b) => a.chained.chainIndex - b.chained.chainIndex)
                .map(element => props.answers_by_code[element.code] || null)
            )
        ]

        if(chainedValues.includes(null)) {
            return []
        } else {
            const {chained:{structure}} = elements.find(element => element.code == props.element.chained.masterCode)
            const myLevelStructure = dot.pick(chainedValues.join("."), structure)
            return Array.isArray(myLevelStructure) ? myLevelStructure : Object.keys(myLevelStructure)
        }
    }

    return <SimpleDropDown {...{...props, element: {...props.element, items}}}/>
}


const SimpleDropDown = ({
    element,
    value = "",
    changeValue,
    errors = []
}) => {

    return (
    
        <div className="fieldset-row">
            <div className={`form-row ${errors.length !== 0 ? 'error' : ''}`}>
                <label>{element.text}</label>
                <select value={value} onChange={({target: {value: newValue}}) => changeValue(newValue)}>
                    <option value=""/>
                    {element.items.map((item, itemIndex) => (
                        <option key={itemIndex} value={item}>{item}</option>
                    ))}
                </select>
                {renderErrors(errors)}
            </div>
        </div>
            
    )
}

const MultipleDropdown = styled(({className, element, value: values = [""], changeValue, errors: errorsList = [] }) => {

    const removeValue = valueIndex => {
        changeValue(
            values.filter(
                (_, index) => index !== valueIndex
            )
        )
    }

    const addValue = () => {
        changeValue(
            [...values, ""]
        )
    }

    const setItemValue = ({value, valueIndex}) => {

        changeValue(
            values.map((v, vIndex) =>
                vIndex !== valueIndex
                    ? v
                    : value
            )
        )
    }

    return (
        <div className={className}>
            {values.map((value, valueIndex) => {

                const isLast = valueIndex === (values.length - 1)
                const errors = errorsList?.[valueIndex] || []

                return (
                    <div className="fieldset-row" key={valueIndex}>
                        <div className={`form-row ${errors.length !== 0 ? 'error' : ''}`}>
                            <label>{element.text}</label>
                            <div className="input-wrapper">
                                <select value={value} onChange={({target: {value}}) => setItemValue({value, valueIndex})}>
                                    <option value=""/>
                                    {element.items.map((item, itemIndex) => (
                                        <option key={itemIndex} value={item}>{item}</option>
                                    ))}
                                </select>
                                {isLast && <i className="icon-plus" onClick={addValue}/>}
                                {!isLast && <i className="icon-ia-delete" onClick={() => removeValue(valueIndex)}/>}
                            </div>

                            {renderErrors(errors)}
                        </div>
                    </div>
                )
            })}
        </div>
    )
})`
  display: flex;
  flex-flow: column;
  row-gap: 3rem;

  .input-wrapper {
    position: relative;
    padding-right: 3.6rem;
    
    > i {
      top: 0;
      right: 0;
      width: 3.6rem;
      height: 3.6rem;
      position: absolute;
      display: block;
      border-radius: 0.3rem;
      font-size: 2rem;
      line-height: 3.6rem;
      text-align: center;
      color: rgba(0,0,0,0.3);
      cursor: pointer;
      transition: color 0.3s ease-in-out,background 0.3s ease-in-out;
      :hover {
        color: #F1F1F1;
        background: #2C2457;;
      }
    }
      
  }
`


const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map((error, i) => {
        if (error === 'required') {
            return <span className="form-row-error-msg">This field is required</span>
        }
    })
}